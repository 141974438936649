<template>
    <div class="laboratory-page">
        <!--        顶部banner图-->
        <div class="middle_img_Box">
            <img
                    src="../../../assets/laboratory/laboratory-banner.png"
                    title="星火实验室"
            />
        </div>
        <!--        中间标题-->
        <div class="middle_title">
            <div v-for="(item,index) in titleData" :key="index" @click="switchModule(index)">
                <div :class="index==currentTitle?'project-display change-color':'project-display'">{{item.title}}</div>
                <div :class="index==currentTitle?'english-title change-english-color':'english-title'">{{item.englishTile}}</div>
            </div>
        </div>
        <!--        底部项目或新闻动态-->
        <div class="container">
            <el-row :gutter="20" v-loading='loading'>
                <el-col
                        :xs="24"
                        :sm="24"
                        :md="6"
                        v-for="(item, index) in dataList"
                        :key="index"
                        class="project_box"
                        @click='goProductDetail(item.id)'
                >
                    <div class="img_box">
                        <img
                                :src="`/api/profile/product/${item.route}`"
                                alt=""
                        />
                    </div>
                    <div class="title_box">
                        <h3 class="case_title">{{ item.name }}</h3>
                        <p class="descri">{{ item.descri }}</p>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
    import {getLaboratoryList, getLaboratoryNews} from "../../../api";
    import {mapState} from "vuex";

    export default {
        name: "laboratoryPage",
        data() {
            return {
                apiUrl: process.env.VUE_APP_URL,
                titleData: [{title: "项目展示", englishTile: "Project display",index:0}, {
                    title: "新闻动态",
                    englishTile: "News information",
                    index:1
                }],
                // 项目展示或新闻展示列表
                dataList: [],
                // 记录当前是项目或新闻
                currentTitle: 0,
                loading: true,

            }
        },
        methods: {
            //跳转详情页
            goProductDetail(data) {
                this.$router.push({
                    path: '/LaboratoryDetail', query: {id: data, type: this.currentTitle}
                })
            },
            //点击标题切换新闻模块index
            switchModule(index) {
                this.loading = true;
                this.currentTitle = index;
                this.$store.commit('modifyVuexLaboratoryActive',index )
                if (index == 0) {
                    this.getLaboratoryListMethods();
                } else {
                    this.getLaboratoryNewsMethods();
                }
            },
            //请求项目展示封装
            getLaboratoryListMethods() {
                getLaboratoryList().then((res) => {
                    this.dataList = res.rows;
                    this.loading = false;

                })
            },
            // 请求新闻展示封装
            getLaboratoryNewsMethods() {
                getLaboratoryNews().then((res) => {
                    this.dataList = res.rows;
                    this.loading = false;
                })
            }
        },
        computed:{
            ...mapState(['laboratoryActive'])
        },
        created() {
            console.log(this.laboratoryActive)
            //首先将用户的操作记录注入到页面
            this.currentTitle=this.laboratoryActive;
            //    首先先获取项目展示新闻
            this.loading = false;
            if (this.laboratoryActive == 0) {
                this.getLaboratoryListMethods();
            } else {
                this.getLaboratoryNewsMethods();
            }
        }
    }
</script>

<style scoped lang="less">
    .middle_img_Box {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
        }
    }

    .middle_title {
        margin: 0 auto;
        width: 50%;
        display: flex;
        justify-content: space-around;
        height: 200px;
        align-items: center;

    }

    .project-display {
        font-size: 22px;
        text-align: center;
        cursor: pointer;
    }

    .english-title {
        color: rgba(0, 0, 0, .6);
        font-size: 22px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        cursor: pointer;

    }

    .descri {
        font-size: 13px;
        line-height: 20px;
        padding: 0 10px;
        color: #666;
        letter-spacing: 1px;
        text-align: left;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        margin: 10px 0;
    }

    .case_title {
        padding: 10px 10px;
        font-size: 16px;

        &:hover {
            text-decoration: underline;
        }
    }

    .project_box {
        cursor: pointer;
        margin-bottom: 20px;

        &:hover .title_box {
            box-shadow: 0 0 5px #ccc;
        }
    }

    .title_box {
        padding-bottom: 20px;
    }

    .img_box {
        overflow: hidden;

        img {
            width: 100%;
            height: 176px;
            transition: 0.5s;

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .container {
        min-height: 100px;
    }
    .change-color{
        color: #71cfff;
    }
    .change-english-color{
        color: rgba(113,207,255,.6);
    }
</style>
