<template>
    <div class="bottom_introduce">
        <div class="container adv">
            <el-row :gutter="30">
                <!-- logo -->
                <el-col class="logo_box" :span="6">
                    <img src="../assets/productImg/wangyue_logo.png" alt=""/>
<!--                    <span class="vertical_bar">|</span>-->
<!--                    <span class="url"> shangyuninfo.com</span>-->
                </el-col>
                <!-- 快速导航 -->
                <el-col :span="5" class="adv_list">
                    <ul>
                        <li class="quick_nav"><span @click="toTop">快速导航</span></li>
                        <li
                                v-for="(item, index) in advName"
                                :key="index"
                                @click="goPage(index)"
                        >
            <span @click="goPage(index)">
              {{ item }}
            </span>
                        </li>
                    </ul>
                </el-col>
                <!-- 官网公众号 -->
                <el-col :span="6" class="second_block"
                ><h4 class="">官方微信公众号</h4>
                    <div>
                        <img :src='`/api/profile/qrCodeImg/${qrCodeUrl}`' alt=""/>
                        <!--            <img :src='`http://192.168.0.123:8085/api/profile/qrCodeImg/${qrCodeUrl}`' alt=""/>-->
                        <!--            <img :src='url' alt=""/>-->
                        <!--            <img src='../assets/productImg/sngyun_pablic.png' alt=""/>-->
                    </div>
                </el-col>
                <!-- 联系我们 -->
                <el-col :span="6" class="contact">
                    <div>
                        <h4>联系我们</h4>
                        <ul>
                            <li v-for="(item,index) in infoList" :key="index">
                                <span class="color_span">{{ item.dictLabel }}: </span>
                                <span>{{ item.dictValue }}</span>
                            </li>
                            <!--              <li>-->
                            <!--                <span class="color_span">电话: </span>-->
                            <!--                <span>173-6795-0373</span>-->
                            <!--              </li>-->
                            <!--              <li>-->
                            <!--                <span class="color_span">邮箱: </span>-->
                            <!--                <span>66970457@QQ.com</span>-->
                            <!--              </li>-->
                        </ul>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="bottomBox">
            <p class="permit">
                备案号：<a href="https://beian.miit.gov.cn/" target="_blank">冀ICP备2023017079号-1</a>
            </p>
            <p>版权所有©河北望岳信息科技有限公司2020-2050保留一切权力;
            </p>
        </div>
    </div>
</template>
<script>
    import {connectUs, qrCode} from "../api";

    export default {
        name: "BottomIntroduce",
        data() {
            return {
                apiUrl: process.env.VUE_APP_URL,
                advName: ["首页", "产品", "星火实验室", "新闻中心", "关于我们"],
                infoList: [],
                qrCodeUrl: "",
                // url: require('../assets/productImg/shangyun_pablic.png'),
            };
        },
        methods: {
            goPage(index) {
                switch (index) {
                    case 0:
                        this.$router.push({path: "/"})
                        break;
                    case 1:
                        this.$router.push({path: "/productPage"});
                        break;
                    case 2:
                        this.$router.push({path: "/laboratory"})
                        break;
                    case 3:
                        this.$router.push({path: "/news"})
                        break;
                    case 4:
                        this.$router.push({path: "/aboutUs"})
                        break;
                }
            },

            handleScroll() {
                let scrolltop =
                    document.documentElement.scrollTop || document.body.scrollTop;
                scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
            },
            toTop() {
                let top = document.documentElement.scrollTop || document.body.scrollTop;
                // 实现滚动效果
                const timeTop = setInterval(() => {
                    document.body.scrollTop = document.documentElement.scrollTop = top -= 80;
                    if (top <= 0) {
                        clearInterval(timeTop);

                    }
                }, 10);
            },
        },
        created() {
            connectUs().then(res => {
                console.log(res)
                this.infoList = res
            })
            //二维码
            //上传二维码成功
            qrCode().then(res => {
                console.log(res)
                this.qrCodeUrl = res.dictValue //图片地址
            })
        },
        mounted() {
            // 此处true需要加上，不加滚动事件可能绑定不成功
            window.addEventListener("scroll", this.handleScroll, true);
        },
    };
</script>
<style scoped lang="less">
    .bottom_introduce {
        background-color: #181819;
        height: 225px;
        min-width: 1200px;
    }

    .adv {
        padding-top: 20px;
    }

    .logo_box {
        padding-top: 40px;

        img {
            width: 120px;
        }
    }

    .vertical_bar {
        color: #333;
        margin-left: 10px;
    }

    .url {
        color: #59585a;
        font-size: 14px;
    }

    .adv_list {
        margin-left: 10px;
        color: #888889;

        li {
            font-size: 14px;
            line-height: 29px;

            &:hover {
                color: #337ab7;
            }

            span {
                cursor: pointer;

            }
        }

        .quick_nav {
            color: #337ab7;
            margin-bottom: 15px;

            span {
                cursor: pointer;
            }
        }
    }

    .second_block {
        h4 {
            color: white;
            font-size: 14px;
        }

        div {
            margin-top: 20px;

            img {
                width: 150px;
            }
        }
    }

    .contact {
        div {
            padding-left: 30px;
        }

        h4 {
            color: white;
            font-size: 14px;
        }

        ul {
            margin-top: 10px;

            li {
                font-size: 14px;
                padding: 10px 0;

                span {
                    color: #888889;
                }

                .color_span {
                    color: #1f8efe;
                }
            }
        }
    }

    .bottomBox {
        height: 47px;
        background-color: #000;

        p {
            font-size: 12px;
            text-align: center;
            color: #888889;
            line-height: 21px;
        }
        .permit{
            padding-top: 5px;
        }
    }
</style>
